import React from "react";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Check from "@material-ui/icons/Check";
import Subject from "@material-ui/icons/Subject";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
import logo from "assets/img/svg/busloclogo.png";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import {Helmet} from "react-helmet";
import city from "assets/img/examples/city.jpg";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(contactsStyle);


export default function SectionContacts({ ...rest }) {
  const [checked, setChecked] = React.useState([]);
  const [specialitySelect,setSpecialitySelect] = React.useState("0");

  const  handleSpeciality = event => {
    
    console.log("event is "+JSON.stringify(event.target));

    setSpecialitySelect(event.target.value)
  };

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const classes = useStyles();

  return (
    <div className="cd-section" {...rest}>
   
   
    <div
      className={`${classes.contacts} ${classes.section}`}
      style={{ backgroundImage: `url(${city})` }}
    >
      <div className={classes.container}>
      <p className={classes.title} style={{ fontSize: '28px',color:"#FFFFFF",marginBottom:10,marginTop:-20,textAlign:'center'}}>Give your Parents School Bus App today</p>
        <GridContainer>
      <GridItem md={4} lg={4}></GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
            <br/><br/>
          <Card className={classes.card1}>



       <form id="schoolsintrested" method="POST" 
       action="https://formspree.io/meqpqarl">
         <CardHeader
         style={{padding:'5px'}}
           contact
           color="rose"
           className={classes.textCenter}
         >
           <h2 className={classes.cardTitle} style={{fontSize:"18px"}}>Intrested in BUSLOC</h2>
         </CardHeader>
         <CardBody>
         
         
           <br/>
          
               
               <CustomInput type="text"
                 labelText="First Name"
                 id="first" name="firstname"
                 formControlProps={{
                   fullWidth: true,
                   name:"firstname"
                 }}
                 inputProps={{
                  name:"firstname"
                 }}
               />
              
           
               <CustomInput
                 labelText="Last Name"
                 id="last" type="text"
                 name="lastname"
                 formControlProps={{
                   fullWidth: true,
                   name:"lastname"
                 }}
                 inputProps={{
                  name:"lastname"
                 }}
               />
           
           <CustomInput
             labelText="Email Address *" type="text"
             id="email-address"
             name="emailaddress"
             formControlProps={{
               fullWidth: true,
               name:"emailaddress"
             }}
             inputProps={{
              name:"emailaddress",
              required:true
             }}
           />
         
               <CustomInput type="text"
                 labelText="School/SchoolDistrict *"
                 id="schoolname" name="schoolname"
                 formControlProps={{
                   fullWidth: true,
                   name : "schoolname"
                 }}
                 inputProps={{
                  name:"schoolname",
                  required:true
                 }}
               />
        
         </CardBody>
         <CardFooter className={classes.justifyContentBetween}>
           {/* <FormControlLabel
             control={
               <Checkbox
                 tabIndex={-1}
                 onClick={() => this.handleToggle(1)}
                 checkedIcon={
                   <Check className={classes.checkedIcon} />
                 }
                 icon={<Check className={classes.uncheckedIcon} />}
                 classes={{
                   checked: classes.checked,
                   root: classes.checkRoot
                 }}
               />
             }
             classes={{ label: classes.label }}
             label="I'm not a robot"
           /> */}
           <input type="hidden" name="_subject" value="schools intrested" />
          
           <Button type="submit" value="Send" round color="rose" className={classes.textCenter} style={{fontWeight:600}}>
            Submit
           </Button>
       
         </CardFooter>
       </form>
     </Card>
          </GridItem>
       {/*    <GridItem xs={12} sm={12} md={8} lg={8}>
          <Card>
      <NavPills
                alignCenter
                color="rose"
                tabs={[
                  { tabButton: "Transportation Staff Demo", tabContent: (
                      <div>
                         <a name="ta">
                          <GridContainer>
                          <GridItem xs={12} sm={12} md={12}   >
          <div className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" ,
        paddingTop: 25,
        height: 0
      }}>
                           <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
       
        src={`https://www.youtube.com/embed/VEDLSLOEi-w?rel=0&autoplay=1&loop=1`}
        allow='autoplay'
        allowFullScreen='allowFullScreen'
        frameBorder="0"
        setPlaybackQuality="hd1080"
        
      
      />
     
     </div>
  
              
                 <br/>
                
                
            </GridItem>
                          </GridContainer>
                          </a>
                      </div>
                  )},
                  { tabButton: "Parent Demo", 
                  tabContent: (
                    <div>
                      <a name="parent">
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={12}   >
    <div className="video"
style={{
  position: "relative",
  paddingBottom: "56.25%" ,
  paddingTop: 25,
  height: 0
}}>
                     <iframe
  style={{
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }}
  
  src={`https://www.youtube.com/embed/oEtdeZR0Itg?rel=0&autoplay=1&loop=1`}
  allow='autoplay'
  allowFullScreen='allowFullScreen'
  frameBorder="0"
  setPlaybackQuality="hd1080"
  

/>
</div>

          
           <br/>
            <Button round color="info" href="https://cp.busloc.io/auth/signup">
              <Subject /> Get started
            </Button>
          
      </GridItem>
                    </GridContainer>
                    </a>
                </div>
                  ) },
                  
                ]}
                />
                </Card>
          </GridItem> */}
         
        </GridContainer>
       
        {/* <GridContainer>
          <GridItem xs={12} sm={3} md={4} lg={4}>
          
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={4}>
          <br/>
          
          </GridItem>
        </GridContainer> */}
      </div>
    </div>
  
  </div>
  );
}
